
import React, { useEffect, useState } from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, formatComponentPageName } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Button as GridButton, Pager, Paging, Column, MasterDetail, Lookup, Editing } from 'devextreme-react/data-grid';
import * as moment from 'moment';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import * as $ from 'jquery';

function OpsCoordinatorCharterFlightInformation() {

    const [isViewingCharterCarriers, setIsViewingCharterCarriers] = useState(false);

    useEffect(() => {
        async function fetchData() {
            //const charterCarrierData = await FetchAPIPromise('DictionaryCharterCarrier');

            //setCharterCarriers(charterCarrierData);
        }

        fetchData();
    }, []);

    const charterCarrierStore = new CustomStore({
        key: 'code',
        load: () => {
            return FetchAPIPromise('DictionaryCharterCarrier');
        },
        insert: (values) => {
            return PostAPIPromise('DictionaryCharterCarrier', values);
        },
        //remove: (key) => {
        //    return DeleteAPIPromise('Office/' + key)
        //        .then((result) => {
        //            if (result.status == -2) {
        //                alert('Office is referenced in the system and cannot be deleted. Consider inactivating.')
        //            }
        //        });
        //},
        update: (key, values) => {
            return PostAPIPromise('DictionaryCharterCarrier', values);
        },
        byKey: function (key) {
            // console.log(key);
            return FetchAPIPromise('DictionaryCharterCarrier/' + key);
        }  
    });

    var lkupDataSourceConfiguration = {
        store: charterCarrierStore
    };  

    const onRowUpdating= (options) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const charterFlightStore = new CustomStore({
        key: 'itineraryId',
        load: () => {
            return FetchAPIPromise('CharterBookings/GetAll');
        },
        //insert: (values) => {
        //    values.contractId = this.state.contractId;
        //    return PostAPIPromise('Office', values);
        //},
        //remove: (key) => {
        //    return DeleteAPIPromise('Office/' + key)
        //        .then((result) => {
        //            if (result.status == -2) {
        //                alert('Office is referenced in the system and cannot be deleted. Consider inactivating.')
        //            }
        //        });
        //},
        update: (key, values) => {
            return PostAPIPromise('CharterBookings', values);
        }
    });

    const dayOfWeekRender = (cell) => {
        if (cell.row.data.requestDate) {
            return moment(cell.row.data.requestDate).format('dddd');
        }
    }

    const charterTypeRender = (cell) => {

        // Basically, if all of the country codes are US, it's domestic. If the country codes start in US, it's international
        //console.log(cell.row.data.flights);

        let charterType = 'Pending';

        cell.row.data.flights.map((item) => {
            if (item.departureAirportCountryCode == '5189' && item.arrivalAirportCountryCode == '5189') {
                charterType = 'Domestic';
            }
            else {
                charterType = 'International';
            }
        })

        return charterType;
    }

    const departureDateScheduledRender = (cell) => {
        let departureDate = 'Pending';

        var initialFlight = cell.row.data.flights.length > 0 && cell.row.data.flights.sort((a, b) => moment(a.departureUTCTime).valueOf() - moment(b.departureUTCTime).valueOf())[0];

        if (initialFlight) {
            return moment(initialFlight.departureLocalTime).format('MM/DD/yyyy');
        }

        return departureDate;
    }

    const departureDayScheduledRender = (cell) => {
        let departureDate = 'Pending';

        var initialFlight = cell.row.data.flights.length > 0 && cell.row.data.flights.sort((a, b) => moment(a.departureUTCTime).valueOf() - moment(b.departureUTCTime).valueOf())[0];

        if (initialFlight) {
            return moment(initialFlight.departureLocalTime).format('dddd');
        }

        return departureDate;
    }

    const missingRender = (cell) => {
        return '****MISSING****';
    }

    const showCharterCarriers = (e) => {
        setIsViewingCharterCarriers(true);
    }

    const hideCharterCarriers = (e) => {
        setIsViewingCharterCarriers(false);
    }

    return (
        <div className="container-fluid">
            <h1>Charter Bookings</h1>
            <br />

            <div className="d-flex justify-content-between">
                <div className="ms-auto">
                    <Button text="Manage Charter Carriers" onClick={showCharterCarriers} />
                </div>
            </div>
            <br />

            <DataGrid dataSource={charterFlightStore} showBorders={true} allowColumnResizing={true} onRowUpdating={onRowUpdating}>
                <Editing allowAdding={false} mode="cell" allowUpdating={true} />

                <Column dataField="requestDate" caption="MVM/UC Charter Date Time COR Requested" dataType="datetime" editorType="dxDateBox" format="MM/dd/yyyy HH:mm" />
                <Column caption="Day of Week COR Requested" editorOptions={{ readOnly: true }} cellRender={dayOfWeekRender} />
                <Column dataField="originatingCarrier" caption="Carrier" editorType="dxSelectBox">
                    <Lookup searchEnabled={true} dataSource={lkupDataSourceConfiguration} displayExpr="name" valueExpr="code" />
                </Column>
                <Column caption="Charter Type" editorOptions={{ readOnly: true }} cellRender={charterTypeRender} />
                <Column dataField="seatCapacity" caption="Seat Capacity" dataType="number" editorOptions={{ min: 0, max: 999 }} />
                <Column dataField="totalCost" caption="Cost" format='currency' precision={3} />

                <Column caption="Departure Date Scheduled" editorOptions={{ readOnly: true }} cellRender={departureDateScheduledRender} dataType="datetime" />
                <Column caption="Day Scheduled" editorOptions={{ readOnly: true }} cellRender={departureDayScheduledRender} />

                <Column dataField="tailNumber" caption="Tail Number" />

                <Column caption="Flight Hours (Total)" editorOptions={{ readOnly: true }} cellRender={missingRender} />
                <Column caption="US Departure City" editorOptions={{ readOnly: true }} cellRender={missingRender} />

                <MasterDetail enabled={true} component={FlightSegment} autoExpandAll={true} />
            </DataGrid>

            <Popup visible={isViewingCharterCarriers} onHiding={hideCharterCarriers} dragEnabled={false}
                closeOnOutsideClick={false} showTitle={true} title="Charter Carriers" width={900} height={500}>
                <DataGrid dataSource={charterCarrierStore} showBorders={true} allowColumnResizing={true} onRowUpdating={onRowUpdating}>
                    <Editing allowAdding={true} mode="popup" allowUpdating={true} />
                    <Column dataField="code" caption="Code" />
                    <Column dataField="name" caption="Name" />
                </DataGrid>
            </Popup>
        </div>);
}

function FlightSegment(props) {

    console.log('incoming props', props);
    //constructor(props) {
    //    //console.log('Detail Props', props);
    //    super(props);
    //    this.dataSource = [props.data.row.data.history];

    //    this.state = {
    //        packageInformation: '',
    //        packageInformationVisible: false
    //    };
    //}

    //viewPackage = (e) => {
    //    //console.log(e);

    //    this.setState({
    //        packageInformationVisible: true,
    //        packageInformation: e.row.data.package
    //    });
    //}

    //hidePackage = (e) => {
    //    this.setState({
    //        packageInformationVisible: false,
    //        packageInformation: ''
    //    });
    //}

    //render() {
    return (
        <div>
            <DataGrid dataSource={props.data.row.data.flights}
                showBorders={true} allowColumnResizing={true}
                keyExpr="id">

                <Column dataField="operatingCarrier" caption="Operating Carrier" />
                <Column dataField="departureAirportCode" caption="Departure Airport Code" />
                <Column dataField="departureAirportName" caption="Departure Airport Name" />
                <Column dataField="departureLocalTime" caption="Departure Time" dataType="datetime" format="MM/dd/yy HH:mm" />

                <Column dataField="arrivalAirportCode" caption="Arrival Airport Code" />
                <Column dataField="arrivalAirportName" caption="Arrival Airport Name" />
                <Column dataField="arrivalLocalTime" caption="Arrival Time" dataType="datetime" format="MM/dd/yy HH:mm" />
            </DataGrid>
        </div>
    );
}

export default OpsCoordinatorCharterFlightInformation;